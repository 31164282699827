import { gql } from '@apollo/client'

export const GET_GLOBAL_NAVIGATION_MENU = gql`
  query GetGlobalNavigationMenu($restaurantGuid: String, $cacheKey: String!) {
    globalNavigationMenu(cacheKey: $cacheKey, restaurantGuid: $restaurantGuid) {
      homeName
      homePath
      homeDescription
      groups {
        name
        globalNavigationItems {
          taskPaths
          categoryType
          description
          longName
          iconHighlightOverridePaths
          icon
          name
          id
          path
        }
      }
    }
  }
`
