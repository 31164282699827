import * as React from 'react'
import {
  GlobalNavigationMenu,
  MenuItemIcon
} from '@local/wex-restaurant-admin-typescript'
import { GlobalNavGroup } from '../GlobalNavGroup'
import { ToastLogoLink } from '@toasttab/buffet-pui-toast-logo'
import { IconButton, LinkButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon, HomeIcon } from '@toasttab/buffet-pui-icons'
import { GlobalNavItem } from '../GlobalNavItem'
import { QueriedCategory } from '../categoryHelpers/categoryTypes'
export interface GlobalNavMenuProps {
  testId?: string | number
  onClose: () => void
  homeName: GlobalNavigationMenu['homeName']
  homePath: GlobalNavigationMenu['homePath']
  homeDescription: GlobalNavigationMenu['homeDescription']
  groups: Array<{ name: string; globalNavigationItems: Array<QueriedCategory> }>
  globalNavOptOut?: () => Promise<boolean>
}

/**
 * GlobalNavMenu component
 *
 */
export const GlobalNavMenu = ({
  testId = 'global-nav-menu',
  groups,
  homePath = '/restaurant/admin/home',
  homeName = 'Home',
  homeDescription = 'Manage POS, menus and reports',
  onClose,
  globalNavOptOut
}: GlobalNavMenuProps) => (
  <div className='flex flex-col w-full h-full overflow-hidden bg-white'>
    <div className='flex flex-row border-b'>
      <ToastLogoLink size='lg' href={homePath} className='hidden md:block' />
      <div className='flex flex-row items-center justify-between w-full px-6x'>
        <IconButton
          textClassName='text-color-secondary'
          aria-label='close'
          icon={<CloseIcon />}
          onClick={onClose}
          testId='globalnav-close-button'
          data-toast-track-id='global-nav-close-nav'
        />
        <div className='hidden sm:block'>
          <LinkButton href={homePath} iconLeft={<HomeIcon />}>
            {homeName}
          </LinkButton>
        </div>
      </div>
    </div>
    <div
      data-testid={testId}
      className='flex flex-col w-full px-6 overflow-y-scroll md:px-8 sm:pt-2 md:pt-4 overscroll-contain'
    >
      <div className='w-full py-4 -ml-2 border-b md:hidden'>
        <GlobalNavItem
          icon={MenuItemIcon.HomeIcon}
          path={homePath}
          title={homeName}
          subtitle={homeDescription}
          testId='global-nav-item-home'
          data-toast-track-id='global-nav-item-home'
        />
      </div>
      {groups.map((group) => (
        <div className='border-b last:border-b-0'>
          <GlobalNavGroup
            {...group}
            key={group.name}
            testId={`global-nav-group-${group.name}`}
          />
        </div>
      ))}
    </div>
    {globalNavOptOut && (
      <div className='flex items-end flex-grow pb-4 ml-1'>
        <LinkButton
          data-testid='global-nav-optout-button'
          data-toast-track-id='global-nav-opt-out'
          onClick={globalNavOptOut}
        >
          Opt out
        </LinkButton>
      </div>
    )}
  </div>
)
