import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from '@local/wex-restaurant-admin-client'
import { GlobalNavigationMenu } from '@local/wex-restaurant-admin-typescript'
import { BanquetProps } from 'banquet-runtime-modules'
import { App } from './App'

const apolloClient = getApolloClient()

export type AppProps = BanquetProps & {
  globalNavigationMenu?: GlobalNavigationMenu
  globalNavOptOut?: () => Promise<boolean>
}

export function AppBootstrap(props: AppProps) {
  return (
    <BrowserRouter basename='restaurants/admin/'>
      <ApolloProvider client={apolloClient}>
        <App {...props} />
      </ApolloProvider>
    </BrowserRouter>
  )
}
