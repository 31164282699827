import * as React from 'react'
import {
  LauncherDrawer,
  LauncherToggle,
  GlobalNavMenu,
  createPortalContainer,
  useGlobalNavState
} from '@local/global-nav'
import { AppProps } from './AppBootstrap'
import { useGetGlobalNavigationMenu } from '@local/wex-restaurant-admin-helpers/src/hooks/useGetGlobalNavigationMenu'
createPortalContainer()

export function App({ globalNavigationMenu, globalNavOptOut }: AppProps) {
  const {
    state: { isOpen },
    setIsOpenTrue,
    setIsOpenFalse
  } = useGlobalNavState()
  const { loading, error, data } = useGetGlobalNavigationMenu({
    globalNavigationMenu
  })
  const displayMenu = !loading && !error && data?.globalNavigationMenu
  return (
    <>
      <LauncherToggle onClick={setIsOpenTrue} />
      <LauncherDrawer isOpen={isOpen} onRequestClose={setIsOpenFalse}>
        {displayMenu && (
          <GlobalNavMenu
            {...data.globalNavigationMenu}
            onClose={setIsOpenFalse}
            globalNavOptOut={globalNavOptOut}
          />
        )}
      </LauncherDrawer>
    </>
  )
}
