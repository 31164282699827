import { QueriedCategory } from '../categoryHelpers/categoryTypes'
import { CategoryType } from '@local/wex-restaurant-admin-typescript'
import * as React from 'react'
import { useLinkClickHandler } from 'react-router-dom'
import { basePath } from '../constants'
import { useGlobalNavState } from '../useGlobalNavState'
import { getCategoryPathWithUtmQueryParams } from '../categoryHelpers/getCategoryPath'

type UseCategoryLocationParams = {
  category: QueriedCategory
}
/**
 * useCategoryLocation
 * Hook to get category location info
 */
export const useCategoryLocation = ({
  category
}: UseCategoryLocationParams): {
  path: string
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  target?: React.HTMLAttributeAnchorTarget
} => {
  const { path } = category
  // if category has no path, default to category
  const actualPath = getCategoryPathWithUtmQueryParams(category)
  // banquet spa links or default category page should use react-router
  // if path contains the bash path or is not relative, we use a normal link
  const isReactRouter =
    (category.categoryType === CategoryType.BanquetLinkCategory || !path) &&
    !actualPath.startsWith('http')
  const { setIsOpenFalse } = useGlobalNavState()
  const reactRouterHandleClick = useLinkClickHandler(
    actualPath.replace(basePath, '')
  )
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    (e) => {
      setIsOpenFalse()
      if (!isReactRouter) {
        return true
      }
      reactRouterHandleClick(e)
    },
    [isReactRouter, reactRouterHandleClick, setIsOpenFalse]
  )

  return {
    onClick,
    path: actualPath,
    target:
      category.categoryType === CategoryType.ExternalLinkCategory
        ? '_blank'
        : undefined
  }
}
