import {
  GetGlobalNavigationMenuQuery,
  GetGlobalNavigationMenuQueryVariables
} from '@local/wex-restaurant-admin-typescript'
import { useBanquetProps } from 'banquet-runtime-modules'
import { useQuery, ApolloError } from '@apollo/client'
import { GET_GLOBAL_NAVIGATION_MENU } from '../queries'

export const useGetGlobalNavigationMenu = ({
  globalNavigationMenu
}: {
  globalNavigationMenu?: GetGlobalNavigationMenuQuery['globalNavigationMenu']
}): {
  data?: GetGlobalNavigationMenuQuery
  loading: boolean
  error?: ApolloError
} => {
  const { restaurantInfo, auth } = useBanquetProps()
  const restaurantGuid = restaurantInfo?.restaurantGuid
  const { data, loading, error } = useQuery<
    GetGlobalNavigationMenuQuery,
    GetGlobalNavigationMenuQueryVariables
  >(GET_GLOBAL_NAVIGATION_MENU, {
    skip: !!globalNavigationMenu || !restaurantGuid,
    variables: {
      cacheKey: auth?.userInfo.binaryCustomerPermissions || '',
      restaurantGuid
    }
  })
  if (globalNavigationMenu) {
    return { data: { globalNavigationMenu }, loading: false, error: undefined }
  }

  return { data, loading, error }
}
