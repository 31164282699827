import { MenuItemIcon } from '@local/wex-restaurant-admin-typescript'
import {
  ReportsIcon,
  EmployeesIcon,
  MenusIcon,
  PickUpIcon,
  PaymentsIcon,
  PayrollEmployeeIcon,
  LoyaltyIcon,
  PlaceSettingIcon,
  HomeIcon,
  ChefsHatIcon,
  IntegrationsIcon,
  ToastLogoIcon,
  CartIcon,
  PercentageIcon,
  LookupChecksIcon,
  BankIcon,
  SettingsIcon,
  XtrachefLogoIcon,
  ServingTrayIcon,
  IconProps
} from '@toasttab/buffet-pui-icons'
import * as React from 'react'

export const iconMap: Record<MenuItemIcon, React.FC<IconProps>> = {
  ReportsIcon,
  EmployeesIcon,
  MenusIcon,
  PickUpIcon,
  PayrollEmployeeIcon,
  PaymentsIcon,
  LoyaltyIcon,
  PercentageIcon,
  LookupChecksIcon,
  PlaceSettingIcon,
  HomeIcon,
  ChefsHatIcon,
  BankIcon,
  IntegrationsIcon,
  ToastLogoIcon,
  CartIcon,
  SettingsIcon,
  XtrachefLogoIcon,
  ServingTrayIcon
}
