import * as React from 'react'
import { QueriedCategory } from '../categoryHelpers/categoryTypes'
import { GlobalNavItemLocalized } from '../GlobalNavItemLocalized'

export type GlobalNavGroupProps = {
  testId?: string | number
  name: string
  globalNavigationItems: Array<QueriedCategory>
}

/**
 * GlobalNavGroup component
 *
 */
export const GlobalNavGroup = ({
  testId = 'global-nav-group',
  name,
  globalNavigationItems
}: GlobalNavGroupProps) => (
  <div data-testid={testId} className='flex flex-col py-4 md:pt-5'>
    <div className='pb-3'>
      <h1 className='type-overline'>{name}</h1>
    </div>
    <div className='grid grid-cols-1 gap-1 -mx-2 sm:grid-cols-2 md:grid-cols-3'>
      {globalNavigationItems.map((category) => (
        <GlobalNavItemLocalized
          category={category}
          key={category.id}
          testId={`global-nav-item-${category.id}`}
        />
      ))}
    </div>
  </div>
)
