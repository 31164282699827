import * as React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuIcon } from '@toasttab/buffet-pui-icons'
import { useCurrentCategory } from '../useCurrentCategory'

export interface LauncherToggleProps {
  testId?: string | number
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

/**
 * LauncherToggle component
 *
 */
export const LauncherToggle = ({
  testId = 'LauncherToggle',
  onClick
}: LauncherToggleProps) => {
  const { currentTitle } = useCurrentCategory()
  return (
    <div className='flex flex-row items-center justify-center'>
      <IconButton
        testId={testId}
        data-toast-track-id='global-nav-open-nav'
        aria-label='Toggle App Launcher Display'
        className='cursor-pointer'
        icon={<MenuIcon className='text-secondary' />}
        onClick={onClick}
      />
      <h1 className='font-semibold type-default whitespace-nowrap ellipsis'>
        {currentTitle}
      </h1>
    </div>
  )
}
