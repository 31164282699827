import * as React from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
type GlobalNavState = { isOpen: boolean }

const globalNavState = makeVar<GlobalNavState>({ isOpen: false })

/**
 * useGlobalNavState
 *
 */
export const useGlobalNavState = () => {
  const state = useReactiveVar(globalNavState)
  const setState = React.useCallback<
    (cb: (currentState: GlobalNavState) => GlobalNavState) => void
  >(
    (cb) => {
      globalNavState(cb(state))
    },
    [state]
  )
  const setIsOpenTrue = React.useCallback<() => void>(() => {
    setState((current) => ({ ...current, isOpen: true }))
  }, [setState])
  const setIsOpenFalse = React.useCallback<() => void>(() => {
    setState((current) => ({ ...current, isOpen: false }))
  }, [setState])
  return { state, setState, setIsOpenTrue, setIsOpenFalse }
}
