import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  RequestHandler
} from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'

const serverUrl = '/api/service/wex-restaurants-admin/v1/graphql'
const persistedQueriesLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true
})

const httpLink = new HttpLink({
  uri: process.env.BFF_SERVER_URL || serverUrl
})

export const getApolloClient = (
  extraLinks?: Array<ApolloLink | RequestHandler>
) => {
  const defaultLink = [persistedQueriesLink, httpLink]
  let links: Array<ApolloLink | RequestHandler> = defaultLink
  if (extraLinks) {
    links = [...defaultLink, ...extraLinks]
  }
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from(links),
    connectToDevTools: true
  })
}
