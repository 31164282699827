import * as React from 'react'
import { useCategoryLocation } from '../useCategoryLocation'
import { GlobalNavItem } from '../GlobalNavItem/GlobalNavItem'
import { QueriedCategory } from '../categoryHelpers/categoryTypes'

export interface GlobalNavItemLocalizedProps {
  testId?: string | number
  category: QueriedCategory
}

/**
 * GlobalNavItemLocalized component
 * GlobalNavItem with router location data
 */
export const GlobalNavItemLocalized = ({
  category,
  testId
}: GlobalNavItemLocalizedProps) => {
  const categoryLocationData = useCategoryLocation({ category })
  return (
    <GlobalNavItem
      {...categoryLocationData}
      icon={category.icon}
      subtitle={category.description}
      title={category.longName}
      testId={testId}
    />
  )
}
