import * as React from 'react'
import { iconMap } from './iconMap'
import cx from 'classnames'
import { MenuItemIcon } from '@local/wex-restaurant-admin-typescript'

export interface GlobalNavItemProps {
  testId?: string | number
  title: string
  subtitle: string
  icon: MenuItemIcon
  path: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  target?: React.HTMLAttributeAnchorTarget
}

const normaliseString = (str: string) =>
  str.replaceAll(' ', '-').replaceAll("'", '').toLowerCase()

/**
 * GlobalNavItem component
 *
 */
export const GlobalNavItem = ({
  subtitle,
  title,
  icon,
  testId = 'global-nav-item',
  path,
  onClick,
  target
}: GlobalNavItemProps) => {
  const Icon = iconMap[icon]

  return (
    <li
      data-testid={testId}
      data-toast-track-id={`global-nav-item-${normaliseString(title)}`}
      className={cx(
        'focus-visible:shadow-focus block rounded-lg',
        'no-underline hover:bg-darken-4'
      )}
    >
      <a href={path} onClick={onClick} target={target}>
        <div className='flex flex-row items-center py-2'>
          <div className='flex flex-row items-center justify-center w-10 h-full'>
            <div className='px-2'>
              {Icon && <Icon className='text-brand-50' />}
            </div>
          </div>
          <div className='flex flex-col mr-2'>
            <span className='font-semibold type-subhead'>{title}</span>
            <span className='type-caption text-secondary'>{subtitle}</span>
          </div>
        </div>
      </a>
    </li>
  )
}
