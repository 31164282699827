import { QueriedCategory } from './categoryTypes'

export const getCategoryPath = (category: QueriedCategory) =>
  category.path || `/restaurants/admin/${category.id}/homepage`

export const getCategoryPathWithUtmQueryParams = (category: QueriedCategory) =>
  addUtmQueryParams(getCategoryPath(category))

function addUtmQueryParams(path: string) {
  const params = '?utm_source=toast-web&utm_medium=plg&utm_campaign=gn-opt-in'
  if (path.includes('?')) {
    return path.substring(0, path.indexOf('?')) + params
  } else return path + params
}
