import { GetGlobalNavigationMenuQuery } from '@local/wex-restaurant-admin-typescript'
import { QueriedCategory } from './categoryTypes'
export const flattenGlobalNavigationMenuCategories = (
  globalNavigationMenu: GetGlobalNavigationMenuQuery['globalNavigationMenu']
): Array<QueriedCategory> => {
  return globalNavigationMenu.groups.reduce((acc, group) => {
    acc.push(...group.globalNavigationItems)
    return acc
  }, [] as Array<QueriedCategory>)
}
