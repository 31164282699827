const portalContainerId = 'globalNavigationPortalContainer'

export const createPortalContainer = () => {
  const modalRoot = global.document.createElement('div')
  modalRoot.setAttribute('id', portalContainerId)
  modalRoot.setAttribute('data-gl-nav', 'true')
  global.document.body.appendChild(modalRoot)
}

export const getNavPortal: () => HTMLElement = () =>
  document.querySelector(`#${portalContainerId}`) || document.body
