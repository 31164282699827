import { QueriedCategory } from '../categoryHelpers/categoryTypes'
import { matchPath } from 'react-router-dom'
import { basePath } from '../constants'
import { getCategoryPath } from '../categoryHelpers/getCategoryPath'
import { useGetGlobalNavigationMenu } from '@local/wex-restaurant-admin-helpers/src/hooks/useGetGlobalNavigationMenu'
import { flattenGlobalNavigationMenuCategories } from '../categoryHelpers/flattenGlobalNavigationMenuCategories'

/**
 * useCurrentCategory
 * Return the category which we are currently in
 */
export const useCurrentCategory = (): {
  isHome: boolean
  category?: QueriedCategory
  currentTitle: string
  loading: boolean
} => {
  const { loading, error, data } = useGetGlobalNavigationMenu({})

  if (loading || error || !data?.globalNavigationMenu) {
    return {
      isHome: false,
      currentTitle: '',
      loading: true
    }
  }
  const { homeName, homePath } = data.globalNavigationMenu
  if (
    matchPath(homePath, window.location.pathname) ||
    matchPath(basePath + homePath, window.location.pathname)
  ) {
    return { isHome: true, currentTitle: homeName, loading: false }
  }
  const currentCategory = flattenGlobalNavigationMenuCategories(
    data?.globalNavigationMenu
  ).find(isCategoryActive)

  return {
    isHome: false,
    currentTitle: currentCategory?.longName || currentCategory?.name || '',
    loading: false,
    category: currentCategory
  }
}
const isCategoryActive = (category: QueriedCategory) =>
  [
    ...category.taskPaths,
    ...category.iconHighlightOverridePaths,
    getCategoryPath(category)
  ].some(
    (p) =>
      matchPath(p, window.location.pathname) ||
      matchPath(basePath + p, window.location.pathname)
  )
