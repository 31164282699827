import * as React from 'react'
// import { Modal } from '@toasttab/buffet-pui-modal'
import cx from 'classnames'
import Modal from 'react-modal'
import { getNavPortal } from '../portalUtils'

export interface LauncherDrawerProps {
  testId?: string | number
  isOpen: boolean
  children: React.ReactNode
  onRequestClose: () => void
}

/**
 * LauncherDrawer component
 *
 */
export const LauncherDrawer = ({
  testId = 'LauncherDrawer',
  isOpen,
  children,
  onRequestClose
}: LauncherDrawerProps) => (
  <Modal
    data-testId={testId}
    isOpen={isOpen}
    className={cx(
      'z-50 w-full outline-none pointer-events-none box-border ',
      'absolute inset-y-0 bottom-0 top-12 md:top-0',
      'max-w-3xl'
    )}
    overlayClassName={cx('bg-darken-56 fixed inset-0 z-50')}
    shouldCloseOnOverlayClick
    shouldCloseOnEsc
    onRequestClose={onRequestClose}
    parentSelector={getNavPortal}
    ariaHideApp={false}
  >
    <div className='absolute inset-0 w-full overflow-hidden bg-white pointer-events-auto md:h-screen'>
      {children}
    </div>
  </Modal>
)
