import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import './index.css'
import { AppBootstrap } from './app/AppBootstrap'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-gl-nav',
  rootComponent: AppBootstrap,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: '0c1b543121e0466fa5e98db73bcb8a75@o37442',
    projectId: '4503976970092544',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'global-nav-spa'
