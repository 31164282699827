import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig
} from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}
export type GetGlobalNavigationMenuQueryVariables = Exact<{
  restaurantGuid?: InputMaybe<Scalars['String']>
  cacheKey: Scalars['String']
}>

export type GetGlobalNavigationMenuQuery = {
  __typename?: 'Query'
  globalNavigationMenu: {
    __typename?: 'GlobalNavigationMenu'
    homeName: string
    homePath: string
    homeDescription: string
    groups: Array<{
      __typename?: 'GlobalNavigationGroup'
      name: string
      globalNavigationItems: Array<{
        __typename?: 'Category'
        taskPaths: Array<string>
        categoryType: CategoryType
        description: string
        longName: string
        iconHighlightOverridePaths: Array<string>
        icon: MenuItemIcon
        name: string
        id: string
        path?: string | null
      }>
    }>
  }
}

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  JSON: any
}

export type BreakdownStats = {
  __typename?: 'BreakdownStats'
  date: Scalars['DateTime']
  id: Scalars['ID']
  laborCost: Scalars['Float']
  netSales: Scalars['Float']
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Category = {
  __typename?: 'Category'
  categorySpa?: Maybe<Scalars['String']>
  categoryType: CategoryType
  description: Scalars['String']
  groupId: Scalars['String']
  headerSpa?: Maybe<Scalars['String']>
  icon: MenuItemIcon
  iconHighlightOverridePaths: Array<Scalars['String']>
  id: Scalars['ID']
  longName: Scalars['String']
  name: Scalars['String']
  path?: Maybe<Scalars['String']>
  sections: Array<Section>
  taskPaths: Array<Scalars['String']>
}

export enum CategoryType {
  BanquetLinkCategory = 'BANQUET_LINK_CATEGORY',
  CategoryPage = 'CATEGORY_PAGE',
  ExternalLinkCategory = 'EXTERNAL_LINK_CATEGORY',
  SpaCategory = 'SPA_CATEGORY'
}

export enum ChangeDirectionEnum {
  Down = 'DOWN',
  None = 'NONE',
  Up = 'UP'
}

export type ChildRestaurantGroupDetails = {
  __typename?: 'ChildRestaurantGroupDetails'
  childGroups: Array<Maybe<Scalars['ID']>>
  childLocations: Array<Maybe<Scalars['ID']>>
  groupGuid: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type Comparison = {
  comparison: ComparisonStats
  current: CurrentStats
  metadata?: Maybe<Metadata>
}

export enum ComparisonDateEnum {
  Sdlw = 'SDLW',
  Sdly = 'SDLY',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY'
}

export enum ComparisonDatePeriodEnum {
  PriorPeriod = 'PRIOR_PERIOD',
  Splw = 'SPLW',
  Sply = 'SPLY'
}

export type ComparisonStats = {
  changeDirection: ChangeDirectionEnum
  day: Scalars['String']
  percentageChange: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type CurrentStats = {
  day: Scalars['String']
  format: FormatEnum
  value: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type DailyStatsTotal = {
  __typename?: 'DailyStatsTotal'
  metadata?: Maybe<MetadataDailyStats>
  netSales: DailyStatsTotalComparison
}

export type DailyStatsTotalComparison = Comparison & {
  __typename?: 'DailyStatsTotalComparison'
  comparison: DailyStatsTotalComparisonStats
  current: DailyStatsTotalCurrentStats
  metadata?: Maybe<Metadata>
}

export type DailyStatsTotalComparisonStats = ComparisonStats & {
  __typename?: 'DailyStatsTotalComparisonStats'
  changeDirection: ChangeDirectionEnum
  day: Scalars['String']
  netSales: Scalars['Float']
  percentageChange: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type DailyStatsTotalCurrentStats = CurrentStats & {
  __typename?: 'DailyStatsTotalCurrentStats'
  day: Scalars['String']
  format: FormatEnum
  netSales: Scalars['Float']
  value: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type DailyStatsTotalOriginal = {
  __typename?: 'DailyStatsTotalOriginal'
  dateRange?: Maybe<Scalars['String']>
  guests: Scalars['Float']
  netSales: Scalars['Float']
  orders: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type DailyStatsTotalResponse = {
  __typename?: 'DailyStatsTotalResponse'
  comparison: Array<Maybe<DailyStatsTotalOriginal>>
  current: Array<Maybe<DailyStatsTotalOriginal>>
  metadata?: Maybe<MetadataDailyStats>
}

export enum DatePeriodEnum {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY'
}

export enum DeliveryMode {
  FirstParty = 'FIRST_PARTY',
  NoDelivery = 'NO_DELIVERY',
  ThirdParty = 'THIRD_PARTY'
}

export type DeliveryStatus = {
  __typename?: 'DeliveryStatus'
  quoteTimesRange?: Maybe<QuoteTimesRange>
  status?: Maybe<OnlineOrderingStatus>
  thirdParty?: Maybe<ThirdPartyDelivery>
}

export enum FormatEnum {
  Currency = 'CURRENCY',
  Percentage = 'PERCENTAGE'
}

export type GlobalNavigationGroup = {
  __typename?: 'GlobalNavigationGroup'
  globalNavigationItems: Array<Category>
  name: Scalars['String']
}

export type GlobalNavigationMenu = {
  __typename?: 'GlobalNavigationMenu'
  groups: Array<GlobalNavigationGroup>
  homeDescription: Scalars['String']
  homeName: Scalars['String']
  homePath: Scalars['String']
}

export type Group = {
  __typename?: 'Group'
  id: Scalars['ID']
  name: Scalars['String']
  tasks: Array<Task>
}

export type Header = {
  __typename?: 'Header'
  links?: Maybe<Array<Link>>
  title: Scalars['String']
}

export type Hourly = {
  __typename?: 'Hourly'
  breakdown: Array<BreakdownStats>
  hourlyStats: Array<HourlyStats>
  metadata?: Maybe<Metadata>
}

export type HourlyStats = {
  __typename?: 'HourlyStats'
  endTime: Scalars['DateTime']
  laborCost?: Maybe<Scalars['Float']>
  netSales?: Maybe<Scalars['Float']>
  numGuests?: Maybe<Scalars['Int']>
  numOrders?: Maybe<Scalars['Int']>
  startTime: Scalars['DateTime']
}

export type Link = {
  __typename?: 'Link'
  link: Scalars['String']
  spaName: Scalars['String']
  title: Scalars['String']
}

export type LocationDetails = {
  __typename?: 'LocationDetails'
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  locationCode?: Maybe<Scalars['String']>
  locationName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  restaurantGuid: Scalars['ID']
  restaurantLeafGuid?: Maybe<Scalars['ID']>
}

export type MenuItem = Header | Link

export enum MenuItemIcon {
  BankIcon = 'BankIcon',
  CartIcon = 'CartIcon',
  ChefsHatIcon = 'ChefsHatIcon',
  EmployeesIcon = 'EmployeesIcon',
  HomeIcon = 'HomeIcon',
  IntegrationsIcon = 'IntegrationsIcon',
  LookupChecksIcon = 'LookupChecksIcon',
  LoyaltyIcon = 'LoyaltyIcon',
  MenusIcon = 'MenusIcon',
  PaymentsIcon = 'PaymentsIcon',
  PayrollEmployeeIcon = 'PayrollEmployeeIcon',
  PercentageIcon = 'PercentageIcon',
  PickUpIcon = 'PickUpIcon',
  PlaceSettingIcon = 'PlaceSettingIcon',
  ReportsIcon = 'ReportsIcon',
  ServingTrayIcon = 'ServingTrayIcon',
  SettingsIcon = 'SettingsIcon',
  ToastLogoIcon = 'ToastLogoIcon',
  XtrachefLogoIcon = 'XtrachefLogoIcon'
}

export type Metadata = {
  __typename?: 'Metadata'
  currentRestaurantDay?: Maybe<Scalars['Int']>
  dataUpToTime?: Maybe<Scalars['String']>
  nextDataUpToTime?: Maybe<Scalars['String']>
  nextRefreshTime?: Maybe<Scalars['String']>
}

export type MetadataDailyStats = {
  __typename?: 'MetadataDailyStats'
  comparisonPeriodEnd: Scalars['String']
  comparisonPeriodStart: Scalars['String']
  dataUpToTime?: Maybe<Scalars['String']>
  periodEnd: Scalars['String']
  periodStart: Scalars['String']
  today?: Maybe<Scalars['String']>
}

export type NetSalesByCategory = {
  __typename?: 'NetSalesByCategory'
  categoryName: Scalars['String']
  id: Scalars['ID']
  percentage: Scalars['Float']
}

export type NetSalesByItem = {
  __typename?: 'NetSalesByItem'
  id: Scalars['ID']
  itemName: Scalars['String']
  netAmount: Scalars['Float']
}

export type OnlineOrdering = {
  __typename?: 'OnlineOrdering'
  delivery?: Maybe<DeliveryStatus>
  enabled?: Maybe<Scalars['Boolean']>
  publicSite?: Maybe<Scalars['String']>
  takeout?: Maybe<TakeoutStatus>
  upgradeUrl?: Maybe<Scalars['String']>
}

export type OnlineOrderingConfigResponse = {
  __typename?: 'OnlineOrderingConfigResponse'
  archived?: Maybe<Scalars['Boolean']>
  createdDate?: Maybe<Scalars['DateTime']>
  deliveryEnabled?: Maybe<Scalars['Boolean']>
  deliveryMode?: Maybe<DeliveryMode>
  guid?: Maybe<Scalars['ID']>
  modifiedDate?: Maybe<Scalars['DateTime']>
  multiLocationId?: Maybe<Scalars['String']>
  onlineOrderingDeliveryDiningOptionSelected?: Maybe<Scalars['Boolean']>
  onlineOrderingEnabled: OnlineOrderingResponseStatus
  onlineOrderingModuleEnabled?: Maybe<Scalars['Boolean']>
  onlineOrderingTakeoutDiningOptionSelected?: Maybe<Scalars['Boolean']>
  onlineOrderingUrl?: Maybe<Scalars['String']>
  owningRestaurantSetId?: Maybe<Scalars['String']>
  revision?: Maybe<Scalars['Int']>
  takeoutEnabled?: Maybe<Scalars['Boolean']>
  targetRestaurantSetId?: Maybe<Scalars['String']>
  tdsDeliveryEnabled?: Maybe<Scalars['Boolean']>
  timeZone?: Maybe<Scalars['String']>
  toastShopAccess?: Maybe<Scalars['Boolean']>
  versionId?: Maybe<Scalars['String']>
}

export enum OnlineOrderingResponseStatus {
  Off = 'OFF',
  On = 'ON',
  Snooze = 'SNOOZE'
}

export enum OnlineOrderingStatus {
  Disabled = 'DISABLED',
  Off = 'OFF',
  On = 'ON',
  Snoozed = 'SNOOZED'
}

export type Overview = {
  __typename?: 'Overview'
  discounts: OverviewComparison
  hourlyLabourCost: OverviewComparison
  metadata?: Maybe<Metadata>
  netSales: OverviewComparison
}

export type OverviewComparison = Comparison & {
  __typename?: 'OverviewComparison'
  comparison: OverviewComparisonStats
  current: OverviewCurrentStats
  metadata?: Maybe<Metadata>
}

export type OverviewComparisonStats = ComparisonStats & {
  __typename?: 'OverviewComparisonStats'
  changeDirection: ChangeDirectionEnum
  day: Scalars['String']
  discountAmount: Scalars['Float']
  netAmount: Scalars['Float']
  pay: Scalars['Float']
  percentageChange: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type OverviewCurrentStats = CurrentStats & {
  __typename?: 'OverviewCurrentStats'
  day: Scalars['String']
  discountAmount: Scalars['Float']
  format: FormatEnum
  netAmount: Scalars['Float']
  pay: Scalars['Float']
  value: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type OverviewResponse = {
  __typename?: 'OverviewResponse'
  data: Array<OverviewStatsOriginal>
  metadata?: Maybe<Metadata>
}

export type OverviewStatsOriginal = {
  __typename?: 'OverviewStatsOriginal'
  discountAmount: Scalars['Float']
  netAmount: Scalars['Float']
  pay: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type ProfileLink = {
  __typename?: 'ProfileLink'
  href: Scalars['String']
  name: Scalars['String']
  type?: Maybe<ProfileLinkType>
}

export enum ProfileLinkType {
  Link = 'LINK',
  Logout = 'LOGOUT',
  QuickRestaurantAccess = 'QUICK_RESTAURANT_ACCESS',
  ShareYourScreen = 'SHARE_YOUR_SCREEN'
}

export type ProfileLinksData = {
  __typename?: 'ProfileLinksData'
  profileLinks: Array<Maybe<ProfileLink>>
  sessionRestaurantUserIsPersistent?: Maybe<Scalars['Boolean']>
}

export type Query = {
  __typename?: 'Query'
  adminMenu: Array<MenuItem>
  availableRestaurants: Array<Maybe<Restaurant>>
  categories: Array<Category>
  /** @deprecated Use getTopSelling */
  getBreakdown: Array<BreakdownStats>
  getDailyStatsTotal: DailyStatsTotal
  /** @deprecated Use getOverview */
  getDiscounts: OverviewComparison
  /** @deprecated Use getOverview */
  getHourlyLabourCost: OverviewComparison
  getHourlyStats: Hourly
  /** @deprecated Use getTopSelling */
  getNetSalesByCategory: Array<NetSalesByCategory>
  /** @deprecated Use getTopSelling */
  getNetSalesByItem: Array<NetSalesByItem>
  getOnlineOrdering: OnlineOrdering
  getOverview: Overview
  /** @deprecated Use getOverview */
  getOverviewMetaData: Metadata
  getProfileLinks: Array<Maybe<ProfileLink>>
  getTopSelling: TopSelling
  getUpdatesContent: Array<UpdateContent>
  globalNavigationMenu: GlobalNavigationMenu
  /** @deprecated Use getProfileLinks instead */
  profileLinks: Array<Maybe<ProfileLink>>
  quickActions: Array<QuickAction>
  restaurantGroupsAccess?: Maybe<RestaurantGroupsAccess>
}

export type QueryCategoriesArgs = {
  cacheKey?: InputMaybe<Scalars['String']>
  restaurantGuid?: InputMaybe<Scalars['String']>
}

export type QueryGetBreakdownArgs = {
  currentDate: ComparisonDateEnum
}

export type QueryGetDailyStatsTotalArgs = {
  comparisonDate: ComparisonDatePeriodEnum
  currentDate: DatePeriodEnum
  restaurantGuid: Scalars['String']
}

export type QueryGetDiscountsArgs = {
  comparisonDate: ComparisonDateEnum
  currentDate: ComparisonDateEnum
}

export type QueryGetHourlyLabourCostArgs = {
  comparisonDate: ComparisonDateEnum
  currentDate: ComparisonDateEnum
}

export type QueryGetHourlyStatsArgs = {
  currentDate: ComparisonDateEnum
  restaurantGuid?: InputMaybe<Scalars['String']>
}

export type QueryGetNetSalesByCategoryArgs = {
  currentDate: ComparisonDateEnum
}

export type QueryGetNetSalesByItemArgs = {
  currentDate: ComparisonDateEnum
}

export type QueryGetOverviewArgs = {
  comparisonDate: ComparisonDateEnum
  currentDate: ComparisonDateEnum
  restaurantGuid?: InputMaybe<Scalars['String']>
}

export type QueryGetOverviewMetaDataArgs = {
  comparisonDate: ComparisonDateEnum
  currentDate: ComparisonDateEnum
}

export type QueryGetTopSellingArgs = {
  currentDate: ComparisonDateEnum
  restaurantGuid?: InputMaybe<Scalars['String']>
}

export type QueryGetUpdatesContentArgs = {
  restaurantGuid?: InputMaybe<Scalars['String']>
}

export type QueryGlobalNavigationMenuArgs = {
  cacheKey?: InputMaybe<Scalars['String']>
  restaurantGuid?: InputMaybe<Scalars['String']>
}

export type QueryRestaurantGroupsAccessArgs = {
  allOf?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  anyOf?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type QuickAction = {
  __typename?: 'QuickAction'
  hasPermission: Scalars['Boolean']
  href?: Maybe<Scalars['String']>
  id: Scalars['String']
  label: Scalars['String']
  showAsNew: Scalars['Boolean']
}

export type QuoteTimeConfigResponse = {
  __typename?: 'QuoteTimeConfigResponse'
  archived: Scalars['Boolean']
  createdDate: Scalars['DateTime']
  deliveryMinLeadTimeMinutes: Scalars['Int']
  deliveryThrottlingTimeMinutes: Scalars['Int']
  deliveryTimeMinutes: Scalars['Int']
  guid?: Maybe<Scalars['ID']>
  modifiedDate: Scalars['DateTime']
  multiLocationId: Scalars['String']
  name: Scalars['String']
  orderPerSlot: Scalars['Int']
  owningRestaurantSetId?: Maybe<Scalars['String']>
  quoteTimeStrategy: QuoteTimeStrategy
  revision: Scalars['Int']
  takeOutMinLeadTimeMinutes: Scalars['Int']
  takeOutPrepTimeMinutes: Scalars['Int']
  takeoutThrottlingTimeMinutes: Scalars['Int']
  targetRestaurantSetId: Scalars['String']
  versionId: Scalars['String']
}

export enum QuoteTimeStrategy {
  Manual = 'MANUAL'
}

export type QuoteTimesRange = {
  __typename?: 'QuoteTimesRange'
  higherMinutes?: Maybe<Scalars['Int']>
  lowerMinutes?: Maybe<Scalars['Int']>
}

export type Restaurant = {
  __typename?: 'Restaurant'
  guid?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  locationCode?: Maybe<Scalars['String']>
  locationName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type RestaurantGroupDetails = {
  __typename?: 'RestaurantGroupDetails'
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>
  guid: Scalars['ID']
  locations?: Maybe<Array<Maybe<Scalars['ID']>>>
  name?: Maybe<Scalars['String']>
}

export type RestaurantGroupsAccess = {
  __typename?: 'RestaurantGroupsAccess'
  groups?: Maybe<Array<Maybe<RestaurantGroupDetails>>>
  locations?: Maybe<Array<Maybe<LocationDetails>>>
}

export type RestaurantGroupsAccessResponse = {
  __typename?: 'RestaurantGroupsAccessResponse'
  groups: Array<ChildRestaurantGroupDetails>
  locations?: Maybe<Array<Maybe<LocationDetails>>>
}

export type Section = {
  __typename?: 'Section'
  groups: Array<Group>
  id: Scalars['ID']
  name: Scalars['String']
  sectionSpa?: Maybe<Scalars['String']>
}

export type TakeoutStatus = {
  __typename?: 'TakeoutStatus'
  quoteTimesRange?: Maybe<QuoteTimesRange>
  status?: Maybe<OnlineOrderingStatus>
}

export type Task = {
  __typename?: 'Task'
  availableActions: Array<TaskAction>
  badgeText: Array<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  /** @deprecated This field is now available directly in the badgeText field. */
  internalOnly: Scalars['Boolean']
  keywords: Array<Scalars['String']>
  /** @deprecated These fields are now available directly in the task object. */
  metadata?: Maybe<Scalars['JSON']>
  name: Scalars['String']
  /** @deprecated Use taskType. New tab links will have taskType == NEW_TAB_LINK. */
  newTab: Scalars['Boolean']
  path: Scalars['String']
  pendoId?: Maybe<Scalars['String']>
  /** @deprecated This field is now available directly in the badgeText field. */
  showBetaTag: Scalars['Boolean']
  taskType: TaskType
}

export type TaskAction = {
  __typename?: 'TaskAction'
  action: Scalars['String']
  description: Scalars['String']
}

export enum TaskType {
  BanquetLink = 'BANQUET_LINK',
  Download = 'DOWNLOAD',
  Link = 'LINK',
  NewTabLink = 'NEW_TAB_LINK',
  Upsell = 'UPSELL'
}

export type ThirdPartyDelivery = {
  __typename?: 'ThirdPartyDelivery'
  name?: Maybe<Scalars['String']>
}

export type TopSelling = {
  __typename?: 'TopSelling'
  metadata?: Maybe<Metadata>
  netSalesByCategory: Array<NetSalesByCategory>
  netSalesByItem: Array<NetSalesByItem>
}

export type TopSellingResponse = {
  __typename?: 'TopSellingResponse'
  data: Array<TopSellingStats>
  metadata?: Maybe<Metadata>
}

export type TopSellingStats = {
  __typename?: 'TopSellingStats'
  categoryName: Scalars['String']
  itemCount: Scalars['Int']
  itemName: Scalars['String']
  netAmount: Scalars['Float']
  yyyymmdd: Scalars['Int']
}

export type UpdateContent = {
  __typename?: 'UpdateContent'
  body?: Maybe<Scalars['String']>
  ctaImage?: Maybe<Scalars['String']>
  ctaText?: Maybe<Scalars['String']>
  ctaUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HourlystatsResponse = {
  __typename?: 'hourlystatsResponse'
  data: Array<HourlyStats>
  metadata?: Maybe<Metadata>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  BreakdownStats: ResolverTypeWrapper<BreakdownStats>
  CacheControlScope: CacheControlScope
  Category: ResolverTypeWrapper<Category>
  CategoryType: CategoryType
  ChangeDirectionEnum: ChangeDirectionEnum
  ChildRestaurantGroupDetails: ResolverTypeWrapper<ChildRestaurantGroupDetails>
  Comparison:
    | ResolversTypes['DailyStatsTotalComparison']
    | ResolversTypes['OverviewComparison']
  ComparisonDateEnum: ComparisonDateEnum
  ComparisonDatePeriodEnum: ComparisonDatePeriodEnum
  ComparisonStats:
    | ResolversTypes['DailyStatsTotalComparisonStats']
    | ResolversTypes['OverviewComparisonStats']
  CurrentStats:
    | ResolversTypes['DailyStatsTotalCurrentStats']
    | ResolversTypes['OverviewCurrentStats']
  DailyStatsTotal: ResolverTypeWrapper<DailyStatsTotal>
  DailyStatsTotalComparison: ResolverTypeWrapper<DailyStatsTotalComparison>
  DailyStatsTotalComparisonStats: ResolverTypeWrapper<DailyStatsTotalComparisonStats>
  DailyStatsTotalCurrentStats: ResolverTypeWrapper<DailyStatsTotalCurrentStats>
  DailyStatsTotalOriginal: ResolverTypeWrapper<DailyStatsTotalOriginal>
  DailyStatsTotalResponse: ResolverTypeWrapper<DailyStatsTotalResponse>
  DatePeriodEnum: DatePeriodEnum
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>
  DeliveryMode: DeliveryMode
  DeliveryStatus: ResolverTypeWrapper<DeliveryStatus>
  Float: ResolverTypeWrapper<Scalars['Float']>
  FormatEnum: FormatEnum
  GlobalNavigationGroup: ResolverTypeWrapper<GlobalNavigationGroup>
  GlobalNavigationMenu: ResolverTypeWrapper<GlobalNavigationMenu>
  Group: ResolverTypeWrapper<Group>
  Header: ResolverTypeWrapper<Header>
  Hourly: ResolverTypeWrapper<Hourly>
  HourlyStats: ResolverTypeWrapper<HourlyStats>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  JSON: ResolverTypeWrapper<Scalars['JSON']>
  Link: ResolverTypeWrapper<Link>
  LocationDetails: ResolverTypeWrapper<LocationDetails>
  MenuItem: ResolversTypes['Header'] | ResolversTypes['Link']
  MenuItemIcon: MenuItemIcon
  Metadata: ResolverTypeWrapper<Metadata>
  MetadataDailyStats: ResolverTypeWrapper<MetadataDailyStats>
  NetSalesByCategory: ResolverTypeWrapper<NetSalesByCategory>
  NetSalesByItem: ResolverTypeWrapper<NetSalesByItem>
  OnlineOrdering: ResolverTypeWrapper<OnlineOrdering>
  OnlineOrderingConfigResponse: ResolverTypeWrapper<OnlineOrderingConfigResponse>
  OnlineOrderingResponseStatus: OnlineOrderingResponseStatus
  OnlineOrderingStatus: OnlineOrderingStatus
  Overview: ResolverTypeWrapper<Overview>
  OverviewComparison: ResolverTypeWrapper<OverviewComparison>
  OverviewComparisonStats: ResolverTypeWrapper<OverviewComparisonStats>
  OverviewCurrentStats: ResolverTypeWrapper<OverviewCurrentStats>
  OverviewResponse: ResolverTypeWrapper<OverviewResponse>
  OverviewStatsOriginal: ResolverTypeWrapper<OverviewStatsOriginal>
  ProfileLink: ResolverTypeWrapper<ProfileLink>
  ProfileLinkType: ProfileLinkType
  ProfileLinksData: ResolverTypeWrapper<ProfileLinksData>
  Query: ResolverTypeWrapper<{}>
  QuickAction: ResolverTypeWrapper<QuickAction>
  QuoteTimeConfigResponse: ResolverTypeWrapper<QuoteTimeConfigResponse>
  QuoteTimeStrategy: QuoteTimeStrategy
  QuoteTimesRange: ResolverTypeWrapper<QuoteTimesRange>
  Restaurant: ResolverTypeWrapper<Restaurant>
  RestaurantGroupDetails: ResolverTypeWrapper<RestaurantGroupDetails>
  RestaurantGroupsAccess: ResolverTypeWrapper<RestaurantGroupsAccess>
  RestaurantGroupsAccessResponse: ResolverTypeWrapper<RestaurantGroupsAccessResponse>
  Section: ResolverTypeWrapper<Section>
  String: ResolverTypeWrapper<Scalars['String']>
  TakeoutStatus: ResolverTypeWrapper<TakeoutStatus>
  Task: ResolverTypeWrapper<Task>
  TaskAction: ResolverTypeWrapper<TaskAction>
  TaskType: TaskType
  ThirdPartyDelivery: ResolverTypeWrapper<ThirdPartyDelivery>
  TopSelling: ResolverTypeWrapper<TopSelling>
  TopSellingResponse: ResolverTypeWrapper<TopSellingResponse>
  TopSellingStats: ResolverTypeWrapper<TopSellingStats>
  UpdateContent: ResolverTypeWrapper<UpdateContent>
  hourlystatsResponse: ResolverTypeWrapper<HourlystatsResponse>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean']
  BreakdownStats: BreakdownStats
  Category: Category
  ChildRestaurantGroupDetails: ChildRestaurantGroupDetails
  Comparison:
    | ResolversParentTypes['DailyStatsTotalComparison']
    | ResolversParentTypes['OverviewComparison']
  ComparisonStats:
    | ResolversParentTypes['DailyStatsTotalComparisonStats']
    | ResolversParentTypes['OverviewComparisonStats']
  CurrentStats:
    | ResolversParentTypes['DailyStatsTotalCurrentStats']
    | ResolversParentTypes['OverviewCurrentStats']
  DailyStatsTotal: DailyStatsTotal
  DailyStatsTotalComparison: DailyStatsTotalComparison
  DailyStatsTotalComparisonStats: DailyStatsTotalComparisonStats
  DailyStatsTotalCurrentStats: DailyStatsTotalCurrentStats
  DailyStatsTotalOriginal: DailyStatsTotalOriginal
  DailyStatsTotalResponse: DailyStatsTotalResponse
  DateTime: Scalars['DateTime']
  DeliveryStatus: DeliveryStatus
  Float: Scalars['Float']
  GlobalNavigationGroup: GlobalNavigationGroup
  GlobalNavigationMenu: GlobalNavigationMenu
  Group: Group
  Header: Header
  Hourly: Hourly
  HourlyStats: HourlyStats
  ID: Scalars['ID']
  Int: Scalars['Int']
  JSON: Scalars['JSON']
  Link: Link
  LocationDetails: LocationDetails
  MenuItem: ResolversParentTypes['Header'] | ResolversParentTypes['Link']
  Metadata: Metadata
  MetadataDailyStats: MetadataDailyStats
  NetSalesByCategory: NetSalesByCategory
  NetSalesByItem: NetSalesByItem
  OnlineOrdering: OnlineOrdering
  OnlineOrderingConfigResponse: OnlineOrderingConfigResponse
  Overview: Overview
  OverviewComparison: OverviewComparison
  OverviewComparisonStats: OverviewComparisonStats
  OverviewCurrentStats: OverviewCurrentStats
  OverviewResponse: OverviewResponse
  OverviewStatsOriginal: OverviewStatsOriginal
  ProfileLink: ProfileLink
  ProfileLinksData: ProfileLinksData
  Query: {}
  QuickAction: QuickAction
  QuoteTimeConfigResponse: QuoteTimeConfigResponse
  QuoteTimesRange: QuoteTimesRange
  Restaurant: Restaurant
  RestaurantGroupDetails: RestaurantGroupDetails
  RestaurantGroupsAccess: RestaurantGroupsAccess
  RestaurantGroupsAccessResponse: RestaurantGroupsAccessResponse
  Section: Section
  String: Scalars['String']
  TakeoutStatus: TakeoutStatus
  Task: Task
  TaskAction: TaskAction
  ThirdPartyDelivery: ThirdPartyDelivery
  TopSelling: TopSelling
  TopSellingResponse: TopSellingResponse
  TopSellingStats: TopSellingStats
  UpdateContent: UpdateContent
  hourlystatsResponse: HourlystatsResponse
}

export type BreakdownStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BreakdownStats'] = ResolversParentTypes['BreakdownStats']
> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  laborCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  netSales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']
> = {
  categorySpa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  categoryType?: Resolver<
    ResolversTypes['CategoryType'],
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  headerSpa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  icon?: Resolver<ResolversTypes['MenuItemIcon'], ParentType, ContextType>
  iconHighlightOverridePaths?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  longName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['Section']>, ParentType, ContextType>
  taskPaths?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChildRestaurantGroupDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChildRestaurantGroupDetails'] = ResolversParentTypes['ChildRestaurantGroupDetails']
> = {
  childGroups?: Resolver<
    Array<Maybe<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  childLocations?: Resolver<
    Array<Maybe<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  groupGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ComparisonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Comparison'] = ResolversParentTypes['Comparison']
> = {
  __resolveType: TypeResolveFn<
    'DailyStatsTotalComparison' | 'OverviewComparison',
    ParentType,
    ContextType
  >
  comparison?: Resolver<
    ResolversTypes['ComparisonStats'],
    ParentType,
    ContextType
  >
  current?: Resolver<ResolversTypes['CurrentStats'], ParentType, ContextType>
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
}

export type ComparisonStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComparisonStats'] = ResolversParentTypes['ComparisonStats']
> = {
  __resolveType: TypeResolveFn<
    'DailyStatsTotalComparisonStats' | 'OverviewComparisonStats',
    ParentType,
    ContextType
  >
  changeDirection?: Resolver<
    ResolversTypes['ChangeDirectionEnum'],
    ParentType,
    ContextType
  >
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  percentageChange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
}

export type CurrentStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentStats'] = ResolversParentTypes['CurrentStats']
> = {
  __resolveType: TypeResolveFn<
    'DailyStatsTotalCurrentStats' | 'OverviewCurrentStats',
    ParentType,
    ContextType
  >
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  format?: Resolver<ResolversTypes['FormatEnum'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
}

export type DailyStatsTotalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyStatsTotal'] = ResolversParentTypes['DailyStatsTotal']
> = {
  metadata?: Resolver<
    Maybe<ResolversTypes['MetadataDailyStats']>,
    ParentType,
    ContextType
  >
  netSales?: Resolver<
    ResolversTypes['DailyStatsTotalComparison'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyStatsTotalComparisonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyStatsTotalComparison'] = ResolversParentTypes['DailyStatsTotalComparison']
> = {
  comparison?: Resolver<
    ResolversTypes['DailyStatsTotalComparisonStats'],
    ParentType,
    ContextType
  >
  current?: Resolver<
    ResolversTypes['DailyStatsTotalCurrentStats'],
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyStatsTotalComparisonStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyStatsTotalComparisonStats'] = ResolversParentTypes['DailyStatsTotalComparisonStats']
> = {
  changeDirection?: Resolver<
    ResolversTypes['ChangeDirectionEnum'],
    ParentType,
    ContextType
  >
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  netSales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  percentageChange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyStatsTotalCurrentStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyStatsTotalCurrentStats'] = ResolversParentTypes['DailyStatsTotalCurrentStats']
> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  format?: Resolver<ResolversTypes['FormatEnum'], ParentType, ContextType>
  netSales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyStatsTotalOriginalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyStatsTotalOriginal'] = ResolversParentTypes['DailyStatsTotalOriginal']
> = {
  dateRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guests?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  netSales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  orders?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyStatsTotalResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyStatsTotalResponse'] = ResolversParentTypes['DailyStatsTotalResponse']
> = {
  comparison?: Resolver<
    Array<Maybe<ResolversTypes['DailyStatsTotalOriginal']>>,
    ParentType,
    ContextType
  >
  current?: Resolver<
    Array<Maybe<ResolversTypes['DailyStatsTotalOriginal']>>,
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['MetadataDailyStats']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type DeliveryStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeliveryStatus'] = ResolversParentTypes['DeliveryStatus']
> = {
  quoteTimesRange?: Resolver<
    Maybe<ResolversTypes['QuoteTimesRange']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['OnlineOrderingStatus']>,
    ParentType,
    ContextType
  >
  thirdParty?: Resolver<
    Maybe<ResolversTypes['ThirdPartyDelivery']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GlobalNavigationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GlobalNavigationGroup'] = ResolversParentTypes['GlobalNavigationGroup']
> = {
  globalNavigationItems?: Resolver<
    Array<ResolversTypes['Category']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GlobalNavigationMenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GlobalNavigationMenu'] = ResolversParentTypes['GlobalNavigationMenu']
> = {
  groups?: Resolver<
    Array<ResolversTypes['GlobalNavigationGroup']>,
    ParentType,
    ContextType
  >
  homeDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  homeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  homePath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tasks?: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeaderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Header'] = ResolversParentTypes['Header']
> = {
  links?: Resolver<
    Maybe<Array<ResolversTypes['Link']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HourlyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Hourly'] = ResolversParentTypes['Hourly']
> = {
  breakdown?: Resolver<
    Array<ResolversTypes['BreakdownStats']>,
    ParentType,
    ContextType
  >
  hourlyStats?: Resolver<
    Array<ResolversTypes['HourlyStats']>,
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HourlyStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HourlyStats'] = ResolversParentTypes['HourlyStats']
> = {
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  laborCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  netSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  numGuests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numOrders?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type LinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']
> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spaName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationDetails'] = ResolversParentTypes['LocationDetails']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  restaurantLeafGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItem'] = ResolversParentTypes['MenuItem']
> = {
  __resolveType: TypeResolveFn<'Header' | 'Link', ParentType, ContextType>
}

export type MetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Metadata'] = ResolversParentTypes['Metadata']
> = {
  currentRestaurantDay?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  dataUpToTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  nextDataUpToTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  nextRefreshTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetadataDailyStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetadataDailyStats'] = ResolversParentTypes['MetadataDailyStats']
> = {
  comparisonPeriodEnd?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  comparisonPeriodStart?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  dataUpToTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  periodEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  periodStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  today?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetSalesByCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSalesByCategory'] = ResolversParentTypes['NetSalesByCategory']
> = {
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetSalesByItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSalesByItem'] = ResolversParentTypes['NetSalesByItem']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnlineOrderingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnlineOrdering'] = ResolversParentTypes['OnlineOrdering']
> = {
  delivery?: Resolver<
    Maybe<ResolversTypes['DeliveryStatus']>,
    ParentType,
    ContextType
  >
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  publicSite?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  takeout?: Resolver<
    Maybe<ResolversTypes['TakeoutStatus']>,
    ParentType,
    ContextType
  >
  upgradeUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnlineOrderingConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnlineOrderingConfigResponse'] = ResolversParentTypes['OnlineOrderingConfigResponse']
> = {
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  createdDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  deliveryEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  deliveryMode?: Resolver<
    Maybe<ResolversTypes['DeliveryMode']>,
    ParentType,
    ContextType
  >
  guid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onlineOrderingDeliveryDiningOptionSelected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onlineOrderingEnabled?: Resolver<
    ResolversTypes['OnlineOrderingResponseStatus'],
    ParentType,
    ContextType
  >
  onlineOrderingModuleEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onlineOrderingTakeoutDiningOptionSelected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onlineOrderingUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  owningRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  takeoutEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  targetRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  tdsDeliveryEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  timeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toastShopAccess?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  versionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Overview'] = ResolversParentTypes['Overview']
> = {
  discounts?: Resolver<
    ResolversTypes['OverviewComparison'],
    ParentType,
    ContextType
  >
  hourlyLabourCost?: Resolver<
    ResolversTypes['OverviewComparison'],
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  netSales?: Resolver<
    ResolversTypes['OverviewComparison'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverviewComparisonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverviewComparison'] = ResolversParentTypes['OverviewComparison']
> = {
  comparison?: Resolver<
    ResolversTypes['OverviewComparisonStats'],
    ParentType,
    ContextType
  >
  current?: Resolver<
    ResolversTypes['OverviewCurrentStats'],
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverviewComparisonStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverviewComparisonStats'] = ResolversParentTypes['OverviewComparisonStats']
> = {
  changeDirection?: Resolver<
    ResolversTypes['ChangeDirectionEnum'],
    ParentType,
    ContextType
  >
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  discountAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  pay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  percentageChange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverviewCurrentStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverviewCurrentStats'] = ResolversParentTypes['OverviewCurrentStats']
> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  discountAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  format?: Resolver<ResolversTypes['FormatEnum'], ParentType, ContextType>
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  pay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverviewResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverviewResponse'] = ResolversParentTypes['OverviewResponse']
> = {
  data?: Resolver<
    Array<ResolversTypes['OverviewStatsOriginal']>,
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverviewStatsOriginalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverviewStatsOriginal'] = ResolversParentTypes['OverviewStatsOriginal']
> = {
  discountAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  pay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfileLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfileLink'] = ResolversParentTypes['ProfileLink']
> = {
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    Maybe<ResolversTypes['ProfileLinkType']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfileLinksDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfileLinksData'] = ResolversParentTypes['ProfileLinksData']
> = {
  profileLinks?: Resolver<
    Array<Maybe<ResolversTypes['ProfileLink']>>,
    ParentType,
    ContextType
  >
  sessionRestaurantUserIsPersistent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  adminMenu?: Resolver<
    Array<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType
  >
  availableRestaurants?: Resolver<
    Array<Maybe<ResolversTypes['Restaurant']>>,
    ParentType,
    ContextType
  >
  categories?: Resolver<
    Array<ResolversTypes['Category']>,
    ParentType,
    ContextType,
    RequireFields<QueryCategoriesArgs, 'cacheKey' | 'restaurantGuid'>
  >
  getBreakdown?: Resolver<
    Array<ResolversTypes['BreakdownStats']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBreakdownArgs, 'currentDate'>
  >
  getDailyStatsTotal?: Resolver<
    ResolversTypes['DailyStatsTotal'],
    ParentType,
    ContextType,
    RequireFields<
      QueryGetDailyStatsTotalArgs,
      'comparisonDate' | 'currentDate' | 'restaurantGuid'
    >
  >
  getDiscounts?: Resolver<
    ResolversTypes['OverviewComparison'],
    ParentType,
    ContextType,
    RequireFields<QueryGetDiscountsArgs, 'comparisonDate' | 'currentDate'>
  >
  getHourlyLabourCost?: Resolver<
    ResolversTypes['OverviewComparison'],
    ParentType,
    ContextType,
    RequireFields<
      QueryGetHourlyLabourCostArgs,
      'comparisonDate' | 'currentDate'
    >
  >
  getHourlyStats?: Resolver<
    ResolversTypes['Hourly'],
    ParentType,
    ContextType,
    RequireFields<QueryGetHourlyStatsArgs, 'currentDate'>
  >
  getNetSalesByCategory?: Resolver<
    Array<ResolversTypes['NetSalesByCategory']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetNetSalesByCategoryArgs, 'currentDate'>
  >
  getNetSalesByItem?: Resolver<
    Array<ResolversTypes['NetSalesByItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetNetSalesByItemArgs, 'currentDate'>
  >
  getOnlineOrdering?: Resolver<
    ResolversTypes['OnlineOrdering'],
    ParentType,
    ContextType
  >
  getOverview?: Resolver<
    ResolversTypes['Overview'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOverviewArgs, 'comparisonDate' | 'currentDate'>
  >
  getOverviewMetaData?: Resolver<
    ResolversTypes['Metadata'],
    ParentType,
    ContextType,
    RequireFields<
      QueryGetOverviewMetaDataArgs,
      'comparisonDate' | 'currentDate'
    >
  >
  getProfileLinks?: Resolver<
    Array<Maybe<ResolversTypes['ProfileLink']>>,
    ParentType,
    ContextType
  >
  getTopSelling?: Resolver<
    ResolversTypes['TopSelling'],
    ParentType,
    ContextType,
    RequireFields<QueryGetTopSellingArgs, 'currentDate'>
  >
  getUpdatesContent?: Resolver<
    Array<ResolversTypes['UpdateContent']>,
    ParentType,
    ContextType,
    Partial<QueryGetUpdatesContentArgs>
  >
  globalNavigationMenu?: Resolver<
    ResolversTypes['GlobalNavigationMenu'],
    ParentType,
    ContextType,
    RequireFields<QueryGlobalNavigationMenuArgs, 'cacheKey' | 'restaurantGuid'>
  >
  profileLinks?: Resolver<
    Array<Maybe<ResolversTypes['ProfileLink']>>,
    ParentType,
    ContextType
  >
  quickActions?: Resolver<
    Array<ResolversTypes['QuickAction']>,
    ParentType,
    ContextType
  >
  restaurantGroupsAccess?: Resolver<
    Maybe<ResolversTypes['RestaurantGroupsAccess']>,
    ParentType,
    ContextType,
    Partial<QueryRestaurantGroupsAccessArgs>
  >
}

export type QuickActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuickAction'] = ResolversParentTypes['QuickAction']
> = {
  hasPermission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  showAsNew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuoteTimeConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuoteTimeConfigResponse'] = ResolversParentTypes['QuoteTimeConfigResponse']
> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  deliveryMinLeadTimeMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  deliveryThrottlingTimeMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  deliveryTimeMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  guid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  modifiedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  orderPerSlot?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  owningRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  quoteTimeStrategy?: Resolver<
    ResolversTypes['QuoteTimeStrategy'],
    ParentType,
    ContextType
  >
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  takeOutMinLeadTimeMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  takeOutPrepTimeMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  takeoutThrottlingTimeMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  targetRestaurantSetId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuoteTimesRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuoteTimesRange'] = ResolversParentTypes['QuoteTimesRange']
> = {
  higherMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  lowerMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Restaurant'] = ResolversParentTypes['Restaurant']
> = {
  guid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantGroupDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantGroupDetails'] = ResolversParentTypes['RestaurantGroupDetails']
> = {
  groups?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ID']>>>,
    ParentType,
    ContextType
  >
  guid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locations?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ID']>>>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantGroupsAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantGroupsAccess'] = ResolversParentTypes['RestaurantGroupsAccess']
> = {
  groups?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RestaurantGroupDetails']>>>,
    ParentType,
    ContextType
  >
  locations?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LocationDetails']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantGroupsAccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantGroupsAccessResponse'] = ResolversParentTypes['RestaurantGroupsAccessResponse']
> = {
  groups?: Resolver<
    Array<ResolversTypes['ChildRestaurantGroupDetails']>,
    ParentType,
    ContextType
  >
  locations?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LocationDetails']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']
> = {
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sectionSpa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TakeoutStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TakeoutStatus'] = ResolversParentTypes['TakeoutStatus']
> = {
  quoteTimesRange?: Resolver<
    Maybe<ResolversTypes['QuoteTimesRange']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['OnlineOrderingStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']
> = {
  availableActions?: Resolver<
    Array<ResolversTypes['TaskAction']>,
    ParentType,
    ContextType
  >
  badgeText?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  internalOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  newTab?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pendoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  showBetaTag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  taskType?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaskActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskAction'] = ResolversParentTypes['TaskAction']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyDeliveryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyDelivery'] = ResolversParentTypes['ThirdPartyDelivery']
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSelling'] = ResolversParentTypes['TopSelling']
> = {
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  netSalesByCategory?: Resolver<
    Array<ResolversTypes['NetSalesByCategory']>,
    ParentType,
    ContextType
  >
  netSalesByItem?: Resolver<
    Array<ResolversTypes['NetSalesByItem']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSellingResponse'] = ResolversParentTypes['TopSellingResponse']
> = {
  data?: Resolver<
    Array<ResolversTypes['TopSellingStats']>,
    ParentType,
    ContextType
  >
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSellingStats'] = ResolversParentTypes['TopSellingStats']
> = {
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  itemCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  itemName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateContent'] = ResolversParentTypes['UpdateContent']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HourlystatsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['hourlystatsResponse'] = ResolversParentTypes['hourlystatsResponse']
> = {
  data?: Resolver<Array<ResolversTypes['HourlyStats']>, ParentType, ContextType>
  metadata?: Resolver<
    Maybe<ResolversTypes['Metadata']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  BreakdownStats?: BreakdownStatsResolvers<ContextType>
  Category?: CategoryResolvers<ContextType>
  ChildRestaurantGroupDetails?: ChildRestaurantGroupDetailsResolvers<ContextType>
  Comparison?: ComparisonResolvers<ContextType>
  ComparisonStats?: ComparisonStatsResolvers<ContextType>
  CurrentStats?: CurrentStatsResolvers<ContextType>
  DailyStatsTotal?: DailyStatsTotalResolvers<ContextType>
  DailyStatsTotalComparison?: DailyStatsTotalComparisonResolvers<ContextType>
  DailyStatsTotalComparisonStats?: DailyStatsTotalComparisonStatsResolvers<ContextType>
  DailyStatsTotalCurrentStats?: DailyStatsTotalCurrentStatsResolvers<ContextType>
  DailyStatsTotalOriginal?: DailyStatsTotalOriginalResolvers<ContextType>
  DailyStatsTotalResponse?: DailyStatsTotalResponseResolvers<ContextType>
  DateTime?: GraphQLScalarType
  DeliveryStatus?: DeliveryStatusResolvers<ContextType>
  GlobalNavigationGroup?: GlobalNavigationGroupResolvers<ContextType>
  GlobalNavigationMenu?: GlobalNavigationMenuResolvers<ContextType>
  Group?: GroupResolvers<ContextType>
  Header?: HeaderResolvers<ContextType>
  Hourly?: HourlyResolvers<ContextType>
  HourlyStats?: HourlyStatsResolvers<ContextType>
  JSON?: GraphQLScalarType
  Link?: LinkResolvers<ContextType>
  LocationDetails?: LocationDetailsResolvers<ContextType>
  MenuItem?: MenuItemResolvers<ContextType>
  Metadata?: MetadataResolvers<ContextType>
  MetadataDailyStats?: MetadataDailyStatsResolvers<ContextType>
  NetSalesByCategory?: NetSalesByCategoryResolvers<ContextType>
  NetSalesByItem?: NetSalesByItemResolvers<ContextType>
  OnlineOrdering?: OnlineOrderingResolvers<ContextType>
  OnlineOrderingConfigResponse?: OnlineOrderingConfigResponseResolvers<ContextType>
  Overview?: OverviewResolvers<ContextType>
  OverviewComparison?: OverviewComparisonResolvers<ContextType>
  OverviewComparisonStats?: OverviewComparisonStatsResolvers<ContextType>
  OverviewCurrentStats?: OverviewCurrentStatsResolvers<ContextType>
  OverviewResponse?: OverviewResponseResolvers<ContextType>
  OverviewStatsOriginal?: OverviewStatsOriginalResolvers<ContextType>
  ProfileLink?: ProfileLinkResolvers<ContextType>
  ProfileLinksData?: ProfileLinksDataResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  QuickAction?: QuickActionResolvers<ContextType>
  QuoteTimeConfigResponse?: QuoteTimeConfigResponseResolvers<ContextType>
  QuoteTimesRange?: QuoteTimesRangeResolvers<ContextType>
  Restaurant?: RestaurantResolvers<ContextType>
  RestaurantGroupDetails?: RestaurantGroupDetailsResolvers<ContextType>
  RestaurantGroupsAccess?: RestaurantGroupsAccessResolvers<ContextType>
  RestaurantGroupsAccessResponse?: RestaurantGroupsAccessResponseResolvers<ContextType>
  Section?: SectionResolvers<ContextType>
  TakeoutStatus?: TakeoutStatusResolvers<ContextType>
  Task?: TaskResolvers<ContextType>
  TaskAction?: TaskActionResolvers<ContextType>
  ThirdPartyDelivery?: ThirdPartyDeliveryResolvers<ContextType>
  TopSelling?: TopSellingResolvers<ContextType>
  TopSellingResponse?: TopSellingResponseResolvers<ContextType>
  TopSellingStats?: TopSellingStatsResolvers<ContextType>
  UpdateContent?: UpdateContentResolvers<ContextType>
  hourlystatsResponse?: HourlystatsResponseResolvers<ContextType>
}
